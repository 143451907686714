import React from "react";
import Cursor from "./components/Cursor";
import Matrix from "./components/Matrix";
import Overlay from "./components/Overlay";
import "./styles/mystyle.css";

function App() {
  return (
    <>
      <Cursor />
      <Matrix />
      <Overlay />
    </>
  );
}

export default App;
